<script setup>
import useClients from "@/hooks/useClients";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import { reactive, onMounted } from "vue";
import { useToast } from "vue-toastification";
import c3api from "@/c3api";
import { useRouter } from "vue-router";

const { clientTypes, fetchClientTypes } = useClients();

const toast = useToast();
const router = useRouter();
const client = reactive({
  name: "",
  nickname: "",
  email: "",
  client_type_id: null,
  phone: null,
  website: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  country: "",
  zip: null,
});

onMounted(async () => {
  setPageTitle("New Client");
  await fetchClientTypes();
});

const validateRequiredFields = () => {
  if (!client.name) {
    toast.error("Name is required.");
    return true;
  }
  // email is not required for now
  // if (!client.email) {
  //   toast.error("Email is required.");
  //   return true;
  // }

  if (!client.client_type_id) {
    toast.error("Account type is required.");
    return true;
  }

  return false;
};

const save = async () => {
  if (validateRequiredFields()) return;

  try {
    const response = await c3api.post("/clients", client);
    toast.success("Client Added Successfully");
    router.push("/clients");
  } catch (err) {
    console.error(err);
    toast.error(err.response.data?.data.join(". "));
  }
};
</script>

<template>
  <v-card-title>{{ pageTitle }}</v-card-title>

  <v-card flat class="mx-4 mt-4">
    <v-card-text>
      <v-row align="center">
        <v-col cols="2">
          <label for="Name">Name <span class="text-red">*</span></label>
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="client.name"
            variant="outlined"
            hide-details="auto"
            placeholder="Name"
            name="Name"
            id="Name"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="Nickname">Nickname</label>
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="client.nickname"
            variant="outlined"
            id="Nickname"
            hide-details="auto"
            placeholder="Nickname"
            name="Nickname"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="accountType">Account Type <span class="text-red">*</span></label>
        </v-col>
        <v-col cols="10">
          <v-autocomplete
            v-model="client.client_type_id"
            variant="outlined"
            :items="clientTypes"
            item-title="name"
            item-value="id"
            id="accountType"
            hide-details="auto"
            placeholder="Select a account type..."
            name="accountType"
          ></v-autocomplete>
        </v-col>

        <v-col cols="2">
          <label for="Email">Email</label>
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="client.email"
            variant="outlined"
            id="Email"
            hide-details="auto"
            placeholder="Email"
            name="Email"
            type="email"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <label>Phone</label>
        </v-col>

        <v-col cols="10">
          <v-phone-input
            v-model="client.phone"
            variant="outlined"
            name="Phone"
          ></v-phone-input>
        </v-col>

        <v-col cols="2">
          <label for="website">Website</label>
        </v-col>

        <v-col cols="10">
          <v-text-field
            prefix="https"
            v-model="client.website"
            variant="outlined"
            hide-details="auto"
            id="website"
            placeholder="Website"
            name="website"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="Street">Street</label>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.address_1"
            variant="outlined"
            id="Street"
            hide-details="auto"
            placeholder="Street"
            name="Street"
          ></v-text-field>
        </v-col>

        <v-col cols="2" class="text-right">
          <label for="Street2">Street 2</label>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.address_2"
            variant="outlined"
            id="Street2"
            hide-details="auto"
            placeholder="Street 2"
            name="Street2"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="City">City</label>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.city"
            variant="outlined"
            id="City"
            hide-details="auto"
            placeholder="City"
            name="City"
          ></v-text-field>
        </v-col>

        <v-col cols="2" class="text-right">
          <label for="State">State</label>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.state"
            variant="outlined"
            id="State"
            hide-details="auto"
            placeholder="State"
            name="State"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <label for="Country">Country</label>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.country"
            variant="outlined"
            id="Country"
            hide-details="auto"
            placeholder="Country"
            name="Country"
          ></v-text-field>
        </v-col>

        <v-col cols="2" class="text-right">
          <label for="Zip">Zip</label>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="client.zip"
            id="Zip"
            variant="outlined"
            hide-details="auto"
            placeholder="Zip"
            name="Zip"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card-actions class="d-flex justify-end mx-2 mt-4">
    <v-btn size="large" variant="flat" color="primary" @click="save"> Save </v-btn>
  </v-card-actions>
</template>
